<script  setup lang="ts">
import axios from 'axios'
import { onMounted, ref, watch } from 'vue'
import useDemandaService from '@/services/DemandaService'
import type FilterDTO from '@/model/FilterDTO'
import { useRouter } from 'vue-router'

let searchInput = ref('');
const searchResults = ref([]);
const debouce = (fn: Function, delay: number) => {
  let timeoutID: any;
  return (...args: any) => {
	if (timeoutID) clearTimeout(timeoutID);
	timeoutID = setTimeout(() => {
	  fn(...args);
	}, delay);
  };
}
let abortController: any = null;
const debounceTime = 180;

const results = ref([]);
const loading = ref(false);
const error = ref(null);
const valorSelecionado = ref(null);
const router = useRouter();


const searchWithDebounce = debouce(async (term: any) => {
  if (abortController) {
	abortController.abort(); // Cancela requisição anterior
  }

  if (!term) {
	results.value = [];
	return;
  }

  abortController = new AbortController(); // Novo AbortController para nova requisição
  loading.value = true;
  error.value = null;

  const filtro: FilterDTO = {
	tituloLike: term,
	size: 5,
	page: 0
  };

  try {
	const response = await useDemandaService().buscarDemandaSearch(filtro, abortController.signal);
	searchResults.value = response.data.content;
  } catch (err) {
	if (err.name !== 'AbortError') { // Ignora erro de requisição abortada
	  error.value = err.message;
	}
  } finally {
	loading.value = false;
  }
}, debounceTime);

onMounted(() => {
 	useDemandaService().getDemandasFiltradas({size: 5, page: 0}).then((response) => {
	   		searchResults.value = response.data.content;
	});
});

const itemProps = (item: any) => {
  return {
	title: item.titulo,
	value: item.id
  }
}

const irParaPagina = () => {
  console.log('ir para página', valorSelecionado.value);
  router.push(`projeto/${valorSelecionado.value}`)
}

watch(searchInput, searchWithDebounce);
watch(valorSelecionado, irParaPagina);
</script>

<template>
  <v-autocomplete
	label="Pesquise sua demanda"
	:items="searchResults"
	variant="solo-inverted"
	density="comfortable"
	class="searchInput"
	no-data-text="Nenhum resultado encontrado"
	:loading="loading"
	:item-props="itemProps"
	prepend-inner-icon="mdi-magnify"
	v-model:search="searchInput"
	v-model="valorSelecionado"
	auto-select-first
	menu-icon=""
  >

  </v-autocomplete>
</template>

<style scoped>
	.searchInput{
	  height: 2em;
	  width: 50%;
	  max-height: 2em;
	  margin-bottom: 5em;
	}
</style>