<script setup lang="ts">

import ToastService from '@/services/ToastService'
import Toast from '@/components/Toast/ToastComponent.vue'

const toasts = ToastService.toasts

</script>

<template>
  <TransitionGroup  name="list" class="container" tag="div">
	<Toast v-for="(toast,index) in toasts" :key="index" :toast="toast" />
  </TransitionGroup>
</template>

<style scoped>
.container{
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: auto;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
;
}

</style>