import axios from 'axios';
import * as APIUtils from '@/utils/APIUtils'
import type AuthenticationRequestDTO from '@/model/RegisterDTO'
import { ToastService } from '@/services/ToastService'
import toast from '@/components/Toast/ToastComponent.vue'
import { CadastrarSucesso, ErroInterno, LoginSucesso } from '@/components/Toast/ToastConst'
import { useCookies } from "vue3-cookies";
import api from '@/apiService'


const api_url = APIUtils.getAPIUrl()
const endpoint = 'user';


export function buscarCoordenadores() {
  return api.get(`${api_url}/${endpoint}/getCoordenadores`);
}

export function patchUsuario(dados: AuthenticationRequestDTO) {
  return api.patch(`${api_url}/${endpoint}/patch`, dados);
}
