import * as APIUtils from '@/utils/APIUtils'
import api from '@/apiService'


export function useParticipacaoService(){
  const api_url = APIUtils.getAPIUrl()
  const endpoint = 'participacao';

  const participarDemanda = (id: number) => {
    return api.post(`${api_url}/${endpoint}/participarDemanda/${id}`);
  }

  const aprovarParticipante = (id: number) => {
    return api.patch(`${api_url}/${endpoint}/aprovarParticipante/${id}`);
  }

  const reprovarParticipante = (id: number) => {
    return api.patch(`${api_url}/${endpoint}/reprovarParticipante/${id}`);
  }

  return {
    api_url,
    endpoint,
    participarDemanda,
    aprovarParticipante,
    reprovarParticipante
  }
}

export default useParticipacaoService;