export function transformarObjetoEmArray<T extends object>(obj: T): Array<{ nome: string } & T[keyof T]> {
  return Object.keys(obj).map((chave) => {
    return {
      nome: chave,
      ...obj[chave as keyof T]
    };
  });
}


export function getEnumColor(valor :any, map:any){
  if(valor == null || map == null || map.value == null){
    return '';
  }


  return map.value.filter( (status:any) =>  status.codigo == valor)[0]?.cor
}
