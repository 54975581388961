<script setup lang="ts">
import MainHeader from '@/components/MainHeader.vue'

import { ref, onMounted, computed } from 'vue'
import EditorMarkdown from '@/components/EditorMarkdown.vue'
import { buscarCoordenadores } from '@/services/UserService'
import usePropriedadeService from '@/services/PropriedadesService'
import { useVuelidate } from '@vuelidate/core'
import { requiredValidator } from '@/validador/required'
import { ToastService } from '@/services/ToastService'
import { DemnandaCriadaComSucesso, PreenchaOsCampos, PreenchaOsCamposDeAcoes } from '@/components/Toast/ToastConst'
import useDemandaService from '@/services/DemandaService'
import { helpers } from '@vuelidate/validators'
import { useRoute, useRouter } from 'vue-router'
import { defineProps } from 'vue'
import { transformarObjetoEmArray } from '@/utils/MapUtil'
import { AcaoTematica } from '@/enum/areaTematicaEnum'
import { AcaoTipo } from '@/enum/acaoTipoEnum'
import { ObjetivoDesenvolvimento } from '@/enum/objetivosDeDesenvolvimentoEnum'
const coordenadores = ref([])

const detalhesDaDemanda = ref("detalhesDaDemanda")

const areasTematicas = ref(transformarObjetoEmArray(AcaoTematica))
const acaoTipos = ref(transformarObjetoEmArray(AcaoTipo))
const objetivosDeDesenvolvimento = ref(transformarObjetoEmArray(ObjetivoDesenvolvimento))

// Acessa o parâmetro de rota como prop
const props = defineProps<{ id: string }>();
const router = useRouter()

onMounted(() => {
  const id  = props.id
  useDemandaService().getDemanda(id as number).then((res) => {
	console.log(res.data)
	form.value = res.data
	form.value.coordenadorId = res.data.coordenador.id
	form.value.acoes = form.value.acoes.map( ((acao) => {
	  return {
		...acao,
		dirty: {
		  tipo: false,
		  descricao: false,
		  publicoDeAcao: false
		}
	  }
	}) )
  });
  console.log(id)

  buscarCoordenadores().then((res) => {
	console.log(res.data)
	coordenadores.value = res.data
  })
})

const form = ref({
  titulo: "",
  descricao: "",
  coordenadorId: null,
  areaTematica: null,
  acoes: [] ,
  objetivoGeral: "",
  fundamentoTeorico: "",
  metodologia: "",
  resultadoEsperado: "",
  elaboracao: "",
  avaliacao: "",
  equipamentoCampus: "",
  espacoFisicoCampus: "",
  recursosFinanceiroCampus: "",
})
let rules = {
  titulo: { requiredValidator },
  descricao: { requiredValidator },
  coordenadorId: { requiredValidator },
  areaTematica: { requiredValidator },
  acoes: {
    $each: helpers.forEach({
	  tipo : {requiredValidator}
    })
  }
  ,
}
const v$ = useVuelidate(rules, form)





const adicionarAcao = () => {
  const acao = {
	numero: form.value.acoes.length + 1,
	tipo: null,
	descricao: "",
	publicoDeAcao: "",
	objetivosDeDesenvolvimento: [],
	dirty: {
	  tipo: false,
	  descricao: false,
	  publicoDeAcao: false
	}
  }
  form.value.acoes.push(acao)
}

const marcarCampoComoTocado = (index, campo) => {
  form.value.acoes[index].dirty[campo] = true
}
const excluirAcao = (index: number) => {
  form.value.acoes = form.value.acoes.filter((acao: any, i: number) => i !== index)
}


const itemPropsCoordenador = (coordenador: any) => {
  return {
	title: coordenador.nome+" "+coordenador.sobrenome,
	value: coordenador.id
  }
}
const itemPropsAreaTematica = (areaTematica: any) => {
  return {
	title: areaTematica.descricao,
	value: areaTematica.codigo
  }
}
const itemPropsAcaoTipo = (acaoTipo: any) => {
  return {
	title: acaoTipo.descricao,
	value: acaoTipo.codigo
  }
}

const editarDemanda = () => {
  console.log(v$)

  v$.value.$validate()
  marcarTodosOsCamposComoTocados()

  const body = {
	...form.value,
	demandanteId : form.value.demandante.id,
	coordenadorId : form.value.coordenador.id

  }

  if (v$.value.$invalid) {
	ToastService.getInstance().toast(PreenchaOsCampos)
	return
  }
  useDemandaService().atualizarDemanda(body).then((res) => {
	ToastService.getInstance().toast(DemnandaCriadaComSucesso);
	router.push( "/");
  }).catch((err) => {
  	console.log(err)
  });

}


const marcarTodosOsCamposComoTocados = () => {
  form.value.acoes.forEach((acao: any, index: number) => {
	acao.dirty.tipo = true
	acao.dirty.descricao = true
	acao.dirty.publicoDeAcao = true
  })
}


</script>


<template>
	<MainHeader/>
  	<div class="background content">
	  <main>
		<span @click="teste">Editar demanda</span>
		<v-text-field
		  label="Titulo da demanda"
		  v-model="form.titulo"
		  :error="v$.titulo.$invalid && v$.titulo.$dirty"
		  :error-messages="v$.titulo.$errors.map(e => e.$message)"
		/>

		<EditorMarkdown
		  label="Descrição da demanda"
		  v-model="form.descricao"
		  :editMode="true"
		  :error="v$.descricao.$invalid && v$.descricao.$dirty"
		  :error-messages="v$.descricao.$errors.map(e => e.$message)"
		/>

		<v-select
		  label="Coordenador"
		  v-model="form.coordenadorId"
		  :items="coordenadores"
		  :item-props="itemPropsCoordenador"
		  :error="v$.coordenadorId.$invalid && v$.coordenadorId.$dirty"
		  :error-messages="v$.coordenadorId.$errors.map(e => e.$message)"
		/>

		<v-select
		  label="Area de atuação"
		  v-model="form.areaTematica"
		  :items="areasTematicas"
		  :item-props="itemPropsAreaTematica"
		  :error="v$.areaTematica.$invalid && v$.areaTematica.$dirty"
		  :error-messages="v$.areaTematica.$errors.map(e => e.$message)"
		/>

		<v-btn @click="adicionarAcao()">Adicionar ação</v-btn>

		<v-expansion-panels class="my-4" variant="popout">
		  <v-expansion-panel  v-for="(acao,index) in form.acoes"  :title="'Ação'"   :key="acao.numero">
			<v-expansion-panel-text>
			  <v-select
				label="Tipo de ação"
				:items="acaoTipos"
				:item-props="itemPropsAcaoTipo"
				v-model="acao.tipo"
				@blur="marcarCampoComoTocado(index, 'tipo')"
				:error="form.acoes[index].dirty.tipo && v$.acoes.$each.$response.$data[index].tipo.$invalid"
				:error-messages="form.acoes[index].dirty.tipo ? v$.acoes.$each.$response.$errors[index].tipo.map ((e) => e.$message ) : null "
			  />



			  <EditorMarkdown
				label="Descrição da ação"
				v-model="acao.descricao"
				:editMode="true"
			  />

			  <v-text-field   label="Público da ação" v-model="acao.publicoDeAcao"/>
			  <v-select
				label="Objetivos de desenvolvimento"
				:items="objetivosDeDesenvolvimento"
				:item-props="itemPropsAreaTematica"
				multiple
			  	v-model="acao.objetivosDeDesenvolvimento"
			  >
				<template v-slot:selection="{ item, index }">
				  <v-chip v-if="index < 2">
					<span>{{ item.title }}</span>
				  </v-chip>
				  <span
					v-if="index === 2"
					class="text-grey text-caption align-self-center"
				  >
        			(+{{ acao.objetivosDeDesenvolvimento.length - 2 }} others)
      				</span>
				</template>
			  </v-select>

			  <v-btn @click="excluirAcao(index)" color="red-darken-4">Excluir ação</v-btn>
			</v-expansion-panel-text>
		  </v-expansion-panel>
		</v-expansion-panels>

		<hr/>
		<span>Detalhes da demanda</span>
		<v-expansion-panels class="my-4" variant="popout" v-model="detalhesDaDemanda">
		  <v-expansion-panel   title='Detalhes'  :value="detalhesDaDemanda" >
			<v-expansion-panel-text>
			  <EditorMarkdown label="Objetivo geral" v-model="form.objetivoGeral" :editMode="true"  />
			  <EditorMarkdown label="Fundamento teórico" v-model="form.fundamentoTeorico" :editMode="true"  />
			  <EditorMarkdown label="Metodologia" v-model="form.metodologia" :editMode="true"  />
			  <EditorMarkdown label="Resultado Esperados" v-model="form.resultadosEsperados" :editMode="true"  />
			  <EditorMarkdown label="Elaboração" v-model="form.elaboracao" :editMode="true"  />
			  <EditorMarkdown label="Avaliação" v-model="form.avaliacao" :editMode= "true"/>
			  <EditorMarkdown label="Equipamento do campus" v-model="form.equipamentoCampus" :editMode="true"  />
			  <EditorMarkdown label="Espaço físico" v-model="form.espacoFisicoCampus" :editMode="true"  />
			  <EditorMarkdown label="Recursos financeiros" v-model="form.recursosFinanceiroCampus" :editMode="true"  />
			</v-expansion-panel-text>
		  </v-expansion-panel>
		</v-expansion-panels>


		<v-btn @click="editarDemanda()" color="primary">Editar demanda</v-btn>
	  </main>
	</div>
</template>

<style lang="scss" scoped >
	.content{
  		display: flex;
	  	flex-direction: column;
	  	justify-content: center;
	  	align-items: center;
	}
	main{
	  width: 80%;
	  margin-top: 6em;
	  margin-bottom: 2em;
	  display: flex;
	  flex-direction: column;
	  gap: 1em;
	  background: #354351;
	  color: white;
	  padding: 1em;
	  border-radius: 1em;
	}



	@media screen and (max-width: 549px) {
	  main{
		width: 100%;
		padding: 1em 0.5em;
	  }
	}

</style>