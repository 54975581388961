<template>
  <MainHeader/>
	  <v-container  fluid class="dashboard-container">
		<v-row>
		  <v-col cols="12">
			<h1 class="text-h4 mb-6 text-white" @click="teste">Dashboard</h1>
		  </v-col>
		</v-row>

		<v-row>
		  <v-col cols="12">
			<v-btn
			  color="primary"
			  @click="criarDemanda"
			  class="mb-6"
			>
			  Criar Nova demanda
			</v-btn>
		  </v-col>
		</v-row>

		<v-row>
		  <v-col cols="12">
			<v-card>
			  <v-data-table-server
				v-model:page="filter.page"
				v-model:headers="headers"
				v-model:items="demandas"
				v-model:items-length="totalDemandas"
				v-model:loading="loading"
				:loading-text="'Carregando demandas'"
				:loading="loading"
				item-value="titulo"
				:no-data-text="'Não há demandas vinculadas ao seu perfil'"
				:items-per-page-options="[5,10,15,20]"
				v-model:items-per-page="filter.size"
				:items-per-page-text="'Demandas por pagina'"
				@update:options="fetchDemandas"
			  >
				<template v-slot:item.statusDemanda="{ item }">
				  <v-chip :color="getStatusColor(item.statusDemanda)" text-color="white">
					{{ getStatusText(item.statusDemanda) }}
				  </v-chip>
				</template>
				<template v-slot:item.actions="{ item }">
				  <img
					:src="searchSVG"
					@click="visualizarDemanda(item.id)"
					class="hoverble"
				  />
				  <img
					v-if="!ehAluno"
					:src="editSVG"
					@click="editarDemanda(item.id)"
					class="hoverble"

				  />
				</template>
				<template v-slot:item.coordenador="{ item }">
				  {{ item?.coordenador?.nome + " " + item?.coordenador?.sobrenome }}
				</template>
				<template v-slot:item.dataCriacao="{ item }">
				  {{ item.dataCriacao ? moment(item.dataCriacao).format('DD/MM/YYYY') : "----" }}
				</template>
			  </v-data-table-server>
			</v-card>
		  </v-col>
		</v-row>
	  </v-container>



</template>

<script setup lang="ts">
import { computed, onMounted, type Ref, ref } from 'vue'
import { useRouter } from 'vue-router'
import MainHeader from '@/components/MainHeader.vue'
import useCookieService from '@/services/CookieService'
import useDemandaService from '@/services/DemandaService'
import { StatusDemanda } from '@/enum/StatusDemandaEnum'
import moment from 'moment'
import type FilterDTO from '@/model/FilterDTO'
import useAuthService from '@/services/AutenticacaoService'
import searchSVG from "@/assets/svg/search.svg"
import editSVG from "@/assets/svg/editar.svg"

const router = useRouter()
const filter :Ref<FilterDTO> = ref({
  page: 1,
  size: 5,
  demandanteId: useCookieService().getAuthCookie().id
})
const authDTO = useCookieService().getAuthCookie()
const loading = ref(false)
const headers = ref([
  { title: 'Titulo', key: 'titulo' },
  { title: 'Status', key: 'statusDemanda' },
  { title: 'Criado em', key: 'dataCriacao' },
  { title: 'Coordenador', key: 'coordenador' },
  { title: 'Ações', key: 'actions' },
])
const demandas = ref([])
const totalDemandas = ref(0) // Quantidade total de registros


const ehAluno = computed( () => {
  return authDTO.role == "ALUNO"
})
const ehDemandante = computed ( () => {
  return authDTO.role == "DEMANDANTE"
})

const ehCoordenador = computed( () => {
  return authDTO.role == "COORDENADOR"
})



const criarDemanda = () => {
  router.push('/criarProjeto')
}
const editarDemanda = (id:number) => {
  router.push({ name: 'editarProjeto', params: { id: id } });
}
const visualizarDemanda = (id:number) => {
  router.push({ name: 'projeto', params: { id: id } });
}

const fetchDemandas = () => {
  loading.value = true
  const tempFilro :FilterDTO = filterByRole()


  useDemandaService().getDemandasFiltradas(tempFilro).then((res) => {
	loading.value=false
	console.log(res)
	demandas.value = res.data.content
	totalDemandas.value = res.data.totalElements // Armazenando o total de registros
  })
}
const filterByRole = () => {
  let tempFiltro:FilterDTO = {
	...filter.value,
  }
  tempFiltro.page -= 1
  if(authDTO.role == "COORDENADOR"){
	tempFiltro.andDemandanteId = authDTO.id
	tempFiltro.andCoordenadorId = authDTO.id
	return  tempFiltro
  }
  if(authDTO.role == "DEMANDANTE"){
	tempFiltro.andDemandanteId = authDTO.id
	return tempFiltro
  }
  if(authDTO.role == "ALUNO"){
	tempFiltro.andDemandanteId = authDTO.id
	return tempFiltro
  }

  return tempFiltro
}
const getStatusColor = (status: number): string => {
  // Iterar sobre as chaves do objeto StatusDemanda
  for (const key in StatusDemanda) {
	if (StatusDemanda[key].codigo === status) {
	  return StatusDemanda[key].cor;
	}
  }
  return 'green';  // Cor padrão caso o status não seja encontrado
}
const getStatusText = (status: number): string => {
  // Iterar sobre as chaves do objeto StatusDemanda
  for (const key in StatusDemanda) {
	if (StatusDemanda[key].codigo === status) {
	  return StatusDemanda[key].descricao;
	}
  }
  return '';  // Cor padrão caso o status não seja encontrado
}


</script>

<style scoped>
.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboard-container {
  margin-top: 2em;
  min-height: 100vh;
  padding: 20px;
}
.hoverble{
  cursor: pointer;
  margin-left: 0.5em;
}
</style>