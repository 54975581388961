import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'
import ProjectsView from '@/views/ProjectsView.vue'
import PerfilView from '@/views/PerfilView.vue'
import useAuthService from '@/services/AutenticacaoService'
import { useCookies } from 'vue3-cookies'
import useCookieService from '@/services/CookieService'
import { UsuarioNaoLogado } from '@/components/Toast/ToastConst'
import { ToastService } from '@/services/ToastService'
import CriarProjeto from '@/views/CriarProjetoView.vue'
import CriarProjetoView from '@/views/CriarProjetoView.vue'
import DashBoard from '@/views/DashBoard.vue'
import moment from 'moment'
import EditarProjetoView from '@/views/EditarProjetoView.vue'
import ProjetoView from '@/views/ProjetoView.vue'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: HomeView
		},
		{
			path:'/login',
			name: "login",
			component: LoginView,
		},
		{
			path:"/criarProjeto",
			name: "criarProjeto",
			component: CriarProjetoView,
			meta: {
				requiresAuth: true
			},
		},
		{
			path: "/editarProjeto/:id",  // Definindo a variável dinâmica ":id"
			name: "editarProjeto",
			component: EditarProjetoView,
			meta: {
				requiresAuth: true
			},
			props: true  // Permitir que o parâmetro seja passado como prop para o componente
		},
		{
			path: "/projeto/:id",  // Definindo a variável dinâmica ":id"
			name: "projeto",
			component: ProjetoView,
			props: true  // Permitir que o parâmetro seja passado como prop para o componente
		},

		{
			path: "/register",
			name: "register",
			component: RegisterView
		},
		{
			path: '/projetos',
			name: 'projetos',
			component: ProjectsView
		},
		{
			path: '/perfil/:id?',
			name: 'perfil',
			component: PerfilView,
			props:true
		},
		{
			path: "/dashboard",
			name: "dashboard",
			component: DashBoard,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/:catchAll(.*)',
			redirect: {
				name: 'home'
			}
		},
	],
})

router.beforeEach((to, from, next) => {
	// Exemplo: verifica se o usuário está logado checando um token no localStorage
	const authCookie = useCookieService().getAuthCookie();
	const token = authCookie.token;
	const expiration = authCookie.expiration;


	const isAuthenticated = token && moment(expiration).isAfter(moment());

	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!isAuthenticated) {
			// Redireciona para a página de login se não estiver autenticado
			ToastService.getInstance().toast(UsuarioNaoLogado)
			next({
				name: 'login',
				query: { redirect: to.fullPath } // Armazena a rota original para redirecionar após login
			});
		} else {
			next(); // Permite a navegação se o usuário estiver autenticado
		}
	} else {
		next(); // Permite a navegação para rotas que não requerem autenticação
	}
});

export default router
