<template>
  <MainHeader/>
  <v-container>
	<v-card class="mx-auto mt-15">
	  <v-img :src="header" height="200" cover></v-img>
	  <v-avatar
		size="100"
		class="avatar-overlay"
		:image="avatar"
		@click="teste "

	  ></v-avatar>
	  <v-card-text>
		<div class="d-flex justify-space-between align-center mb-4 mt-10">
		  <div class="d-flex flex-column w-100">
			<v-row v-if="editMode">
			  <v-col cols="6" md="6">
				<v-text-field
				  v-if="editMode"
				  v-model.lazy="v$.nome.$model"
				  :error-messages="v$.nome.$errors.map(e => e.$message)"
				  label="Nome"
				  counter="15"
				  dense
				></v-text-field>
			  </v-col>
			  <v-col cols="6" md="6">
				<v-text-field
				  v-if="editMode"
				  v-model.lazy="v$.sobrenome.$model"
				  :error-messages="v$.sobrenome.$errors.map(e => e.$message)"
				  label="Sobrenome"
				  counter="15"
				  dense
				></v-text-field>
			  </v-col>
			</v-row>
			<h2 v-else class="text-h4 font-weight-bold">
			  {{ fullName }}
			</h2>
			<v-row>
			  <v-col cols="12"><p class="text-subtitle-1">{{ profile.role.charAt(0).toUpperCase()+profile.role.toLowerCase().slice(1) }}</p></v-col>
			</v-row>
		  </div>
		  <v-btn
			v-if="editMode"
			icon="mdi-check"
			color="success"
			@click="handleSave"
		  ></v-btn>
		  <v-btn v-else icon="mdi-pencil" @click="editMode = true"></v-btn>
		</div>

		<v-tabs v-model="tab">
		  <v-tab value="about">Sobre</v-tab>
		  <v-tab value="demands">Demandas</v-tab>
		</v-tabs>

		<v-window v-model="tab">
		  <v-window-item value="about">
			<v-container>
			  <h3 v-if="profile.bio || editMode" class="text-h6 font-weight-bold mb-2">Bio</h3>
			  <v-textarea
				v-if="editMode"
				v-model.lazy="v$.bio.$model"
				:error-messages="v$.bio.$errors.map(e => e.$message)"
				rows="3"
				counter="700"
				auto-grow
			  ></v-textarea>
			  <p v-else>
				<EditorMarkdown v-model="profile.bio" :counter="true" :limitChar="700"/>
			  </p>

			  <v-row>
				<v-col cols="12" md="6">
				  <h3 class="text-h6 font-weight-bold mb-2">
					Informações de Contato
				  </h3>
				  <v-list>
					<v-list-item>
					  <template v-slot:prepend>
						<v-icon icon="mdi-email"></v-icon>
					  </template>
					  <v-list-item-title>{{ profile.email }}</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="profile.telefone || editMode">
					  <template v-slot:prepend>
						<v-icon icon="mdi-phone"></v-icon>
					  </template>
					  <v-list-item-title>
						<v-text-field
						  v-if="editMode"
						  v-model.lazy="v$.telefone.$model"
						  :error-messages="v$.telefone.$errors.map(e => e.$message)"
						  label="Telefone"
						  counter="30"
						  type="tel"
						  dense

						></v-text-field>
						<span v-else>{{ profile.telefone }}</span>
					  </v-list-item-title>
					</v-list-item>
					<v-list-item v-if="profile.website || editMode">
					  <template v-slot:prepend>
						<v-icon icon="mdi-web"></v-icon>
					  </template>
					  <v-list-item-title>
						<v-text-field
						  v-if="editMode"
						  v-model.lazy="v$.website.$model"
						  :error-messages="v$.website.$errors.map(e => e.$message)"
						  label="Website"
						  counter="30"
						  dense
						></v-text-field>
						<a
						  v-else
						  :href="profile.website"
						  target="_blank"
						  rel="noopener noreferrer"
						>
						  {{ profile.website }}
						</a>
					  </v-list-item-title>
					</v-list-item>
				  </v-list>
				</v-col>
				<v-col cols="12" md="6" v-if="possuiAoMenosUmaRede || editMode">
				  <h3 class="text-h6 font-weight-bold mb-2">Redes Sociais</h3>
				  <v-list>
					<!-- Facebook -->
					<v-list-item v-if="possuiAoMenosUmaRede || editMode">
					  <template v-slot:prepend>
						<v-icon>mdi-facebook</v-icon>
					  </template>
					  <v-list-item-title>
						<v-text-field
						  v-if="editMode"
						  v-model="v$.facebook.$model"
						  label="Facebook"
						  :error-messages="v$.facebook.$errors.map(e => e.$message)"
						  counter="30"
						  dense
						></v-text-field>
						<a
						  v-else
						  :href="profile.facebook"
						  target="_blank"
						  rel="noopener noreferrer"
						>
						  {{ profile.facebook }}
						</a>
					  </v-list-item-title>
					</v-list-item>

					<!-- Instagram -->
					<v-list-item v-if="socialNetworks.some(social => social.name === 'instagram')">
					  <template v-slot:prepend>
						<v-icon>mdi-instagram</v-icon>
					  </template>
					  <v-list-item-title>
						<v-text-field
						  v-if="editMode"
						  v-model="v$.instagram.$model"
						  label="Instagram"
						  :error-messages="v$.instagram.$errors.map(e => e.$message)"
						  counter="30"
						  dense
						></v-text-field>
						<a
						  v-else
						  :href="profile.instagram"
						  target="_blank"
						  rel="noopener noreferrer"
						>
						  {{ profile.instagram }}
						</a>
					  </v-list-item-title>
					</v-list-item>

					<!-- LinkedIn -->
					<v-list-item v-if="socialNetworks.some(social => social.name === 'linkedin')">
					  <template v-slot:prepend>
						<v-icon>mdi-linkedin</v-icon>
					  </template>
					  <v-list-item-title>
						<v-text-field
						  v-if="editMode"
						  v-model="v$.linkedin.$model"
						  label="LinkedIn"
						  :error-messages="v$.linkedin.$errors.map(e => e.$message)"
						  counter="30"
						  dense
						></v-text-field>
						<a
						  v-else
						  :href="profile.linkedin"
						  target="_blank"
						  rel="noopener noreferrer"
						>
						  {{ profile.linkedin }}
						</a>
					  </v-list-item-title>
					</v-list-item>

					<!-- Twitter -->
					<v-list-item v-if="socialNetworks.some(social => social.name === 'twitter')">
					  <template v-slot:prepend>
						<v-icon>mdi-twitter</v-icon>
					  </template>
					  <v-list-item-title>
						<v-text-field
						  v-if="editMode"
						  v-model="v$.twitter.$model"
						  label="Twitter"
						  :error-messages="v$.twitter.$errors.map(e => e.$message)"
						  counter="30"
						  dense
						></v-text-field>
						<a
						  v-else
						  :href="profile.twitter"
						  target="_blank"
						  rel="noopener noreferrer"
						>
						  {{ profile.twitter }}
						</a>
					  </v-list-item-title>
					</v-list-item>

					<!-- GitHub -->
					<v-list-item v-if="socialNetworks.some(social => social.name === 'github')">
					  <template v-slot:prepend>
						<v-icon>mdi-github</v-icon>
					  </template>
					  <v-list-item-title>
						<v-text-field
						  v-if="editMode"
						  v-model="v$.github.$model"
						  label="GitHub"
						  :error-messages="v$.github.$errors.map(e => e.$message)"
						  counter="30"
						  dense
						></v-text-field>
						<a
						  v-else
						  :href="profile.github"
						  target="_blank"
						  rel="noopener noreferrer"
						>
						  {{ profile.github }}
						</a>
					  </v-list-item-title>
					</v-list-item>
				  </v-list>
				</v-col>
			  </v-row>
			</v-container>
		  </v-window-item>

		  <v-window-item value="demands">
			<v-container>
			  <h3 class="text-h6 font-weight-bold mb-2">Demandas</h3>
			  <v-expansion-panels class="my-4" variant="popout">
				<v-expansion-panel title="Demandas como aluno">
				  <v-expansion-panel-text>
					<div v-if="profile.demandas.demandasComoAluno.length > 0">
					  <h2>Demandas como Aluno</h2>
					  <div class="demandasContainer__demanda" v-for="demandasComoAluno in profile.demandas.demandasComoAluno"  :key="demandasComoAluno.id">
						<div class="demandasContainer__title">
						  <span>{{ demandasComoAluno.titulo.trim() }} </span>
						  <v-chip>Status</v-chip>
						</div>
					  </div>
					</div>
					<div v-else>
					  <span>Nenhuma demanda como aluno</span>
					</div>
				  </v-expansion-panel-text>
				</v-expansion-panel>

				<v-expansion-panel title="Demandas como organizador">
				  <v-expansion-panel-text>
					<div v-if="profile.demandas.demandasComoCoordenador.length > 0">
					  <v-list lines="two">
						<v-list-item
						  v-for="demandaCoordenador in profile.demandas.demandasComoCoordenador"
						  :key="demandaCoordenador.id"
						  color="primary"
						  rounded="shaped">
						  <v-list-item-title>{{ demandaCoordenador.titulo.trim() }}</v-list-item-title>
						  <v-list-item-subtitle>Status</v-list-item-subtitle>
						</v-list-item>
					  </v-list>
					</div>
					<div v-else>
					  <span>Nenhuma demanda participando</span>
					</div>
				  </v-expansion-panel-text>
				</v-expansion-panel>

				<v-expansion-panel title="Demandas solicitadas">
				  <v-expansion-panel-text>
					<div v-if="profile.demandas.demandasComoDemandante.length > 0">
					  <h2>Demandas solicitadas</h2>
					  <div class="demandasContainer__demanda" v-for="demandasComoDemandante in profile.demandas.demandasComoDemandante"  :key="demandasComoDemandante.id">
						<div class="demandasContainer__title">
						  <span>{{ demandasComoDemandante.titulo.trim() }} </span>
						  <v-chip>Status</v-chip>
						</div>
					  </div>
					</div>
					<div v-else>
					  <span>Nenhuma demanda solicitada</span>
					</div>
				  </v-expansion-panel-text>
				</v-expansion-panel>
			  </v-expansion-panels>
			</v-container>
		  </v-window-item>
		</v-window>
	  </v-card-text>
	</v-card>
  </v-container>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, defineProps } from 'vue'
import moment from 'moment'
import EditorMarkdown from '@/components/EditorMarkdown.vue'
import MainHeader from '@/components/MainHeader.vue'
import { useProfileService } from '@/services/ProfileService'
import { requiredValidator } from '@/validador/required'
import { helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { size } from '@/validador/size'
import { useVuelidate } from '@vuelidate/core'
import { ToastService } from '@/services/ToastService'
import { MudancasSalvas, PreenchaOsCampos, PreenchaOsCamposCorretamente } from '@/components/Toast/ToastConst'

let rules = {
  nome: {
	required: helpers.withMessage("O campo nome é obrigatório", required),
	minLength: helpers.withMessage("Nome muito curto (min 3)", minLength(3)),
	maxLength: helpers.withMessage("Nome muito longo (max 15)", maxLength(15))
  },
  sobrenome: {
	minLength: helpers.withMessage("Sobrenome muito curto (min 3)", minLength(3)),
	maxLength: helpers.withMessage("Sobrenome muito longo (max 15)", maxLength(15))
  },
  telefone: {
	maxLength: helpers.withMessage("Telefone muito longo (max 30)", maxLength(30))
  },
  email: {
	required: helpers.withMessage("O campo email é obrigatório", required),
	minLength: helpers.withMessage("Email muito curto (min 3)", minLength(3)),
	maxLength: helpers.withMessage("Email muito longo (max 25)", maxLength(25))
  },
  cpf: {
	maxLength: helpers.withMessage("CPF muito longo (max 25)", maxLength(25))
  },
  cnpj: {
	maxLength: helpers.withMessage("CNPJ muito longo (max 18)", maxLength(18))
  },
  bio: {
	maxLength: helpers.withMessage("Bio muito longa (max 700)", maxLength(700))
  },
  website: {
	maxLength: helpers.withMessage("Website muito longo (max 30)", maxLength(30))
  },
	facebook: {
	  maxLength: helpers.withMessage("Facebook muito longo (max 30)", maxLength(30))
	},
	instagram: {
	  maxLength: helpers.withMessage("Instagram muito longo (max 30)", maxLength(30))
	},
	twitter: {
	  maxLength: helpers.withMessage("Twitter muito longo (max 30)", maxLength(30))
	},
	linkedin: {
	  maxLength: helpers.withMessage("LinkedIn muito longo (max 30)", maxLength(30))
	},
	github: {
	  maxLength: helpers.withMessage("GitHub muito longo (max 30)", maxLength(30))
	}

}


const props = defineProps({
  id: {
	type: Number,
	required: true
  }})
const profile = ref({
  nome: 'Nome',
  sobrenome: 'Sobrenome',
  email: 'email@example.com',
  role: 'Tipo de Usuário',
  avatar: 'https://cdn.goconqr.com/uploads/quiz_answer/image/720949/desktop_Volibear_Square_0.png',
  header: 'https://s2-receitas.glbimg.com/4fsKib2sTrno-lcvH5a7JSKH-3o=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_1f540e0b94d8437dbbc39d567a1dee68/internal_photos/bs/2021/1/F/t5DLoeQGq03wncdqnYWA/mousse-de-maracuja.jpg',
  bio: 'Uma breve biografia do usuário...',
  dataNascimento: moment().format('YYYY-MM-DD'),
  telefone: '(00) 00000-0000',
  website: 'https://exemplo.com',
  facebook: 'https://facebook.com/usuario',
  instagram: 'https://instagram.com/usuario',
  linkedin: 'https://linkedin.com/in/usuario',
  twitter: 'https://twitter.com/usuario',
  github: 'https://github.com/usuario',
})
const v$ = useVuelidate(rules,profile)
const editMode = ref(false)
const avatar =  'https://cdn.goconqr.com/uploads/quiz_answer/image/720949/desktop_Volibear_Square_0.png'
const header = 'https://s2-receitas.glbimg.com/4fsKib2sTrno-lcvH5a7JSKH-3o=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_1f540e0b94d8437dbbc39d567a1dee68/internal_photos/bs/2021/1/F/t5DLoeQGq03wncdqnYWA/mousse-de-maracuja.jpg'
const tab = ref('about')
const socialNetworks = [
  { name: 'facebook', icon: 'mdi-facebook', label: 'Facebook' },
  { name: 'instagram', icon: 'mdi-instagram', label: 'Instagram' },
  { name: 'linkedin', icon: 'mdi-linkedin', label: 'LinkedIn' },
  { name: 'twitter', icon: 'mdi-twitter', label: 'Twitter' },
  { name: 'github', icon: 'mdi-github', label: 'GitHub' },
]




const fullName = computed( () => `${profile.value.nome} ${profile.value.sobrenome}` )



const possuiAoMenosUmaRede = computed ( () =>{
  return profile.value.facebook || profile.value.github || profile.value.twitter || profile.value.linkedin || profile.value.instagram
})
const teste = () =>{
  console.log(v$.value)
  console.log(Object.values(v$.value).find( (objeto) => objeto?.$path == 'twitter').$errors.map( e => $message))
}
const handleSave = () => {
  v$.value.$validate()
  console.log(v$.value)
  if (v$.value.$invalid) {
	ToastService.getInstance().toast(PreenchaOsCamposCorretamente)
	return
  }
  useProfileService().updateProfile(profile.value).then( () =>{
	ToastService.getInstance().toast(MudancasSalvas);
	editMode.value = false;
	})

}
onMounted(() => {
  console.log(props.id)
  useProfileService().getProfile(props.id).then( (res) => {
	profile.value = res.data
	console.log(res.data)
  })
})
</script>

<style scoped>
.avatar-overlay {
  position: absolute;
  top: 150px;
  left: 16px;
  border: 4px solid white;
}
</style>