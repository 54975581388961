import type DemandaDTO from '@/model/DemandaDTO';
import type Pageable from '@/model/PageableResponseDTO'
import * as APIUtils from '@/utils/APIUtils'
import api from '@/apiService'
import type FilterDTO from '@/model/FilterDTO'
import type DemandaRequest from '@/model/DemandaRequest'


export function useDemandaService(){
  const api_url = APIUtils.getAPIUrl();
  const endpoint = 'demanda';

  const getDemanda = (id: number) => {
    return api.get<DemandaDTO>(`${api_url}/${endpoint}/buscarDemanda/${id}`);
  }

  const getDemandas = () => {
    return api.get<Pageable<DemandaDTO>>(`${api_url}/${endpoint}/listarDemandas`);
  }

  const getDemandasFiltradas = (filtros: FilterDTO = {}) => {
    return api.post(`${api_url}/${endpoint}/listarDemandas`,filtros);
  }

  const atualizarStatus = (idDemanda : number , codigoStatus : number) => {
    return api.patch(`${api_url}/${endpoint}/mudarStatusDemanda/${idDemanda}/${codigoStatus}`,)
  }
  const atualizarDemanda = (demanda: DemandaDTO) => {
    return api.put<DemandaDTO>(`${api_url}/${endpoint}/atualizarDemanda`, demanda);
  }

  const criarDemanda = (demanda: DemandaRequest) => {
    return api.post<DemandaDTO>(`${api_url}/${endpoint}/criarDemanda`, demanda);
  }

  const buscarDemandaSearch = (filtros: FilterDTO, abort : any) => {
    return api.post(`${api_url}/${endpoint}/listarDemandas`,
      filtros ,
      {signal : abort, }
    );
  }

  const deletarDemanda = (id: number) => {
    return api.delete(`${api_url}/${endpoint}/deletarDemanda/${id}`);
  }

  return {
    api_url,
    endpoint,
    getDemanda,
    getDemandas,
    getDemandasFiltradas,
    atualizarDemanda,
    criarDemanda,
    deletarDemanda,
    atualizarStatus,
    buscarDemandaSearch
  }



}

export default useDemandaService;



