import { helpers } from '@vuelidate/validators'

function isRequired(value: string) {
  // Verifica se o valor não está vazio
  if(value === undefined || value === null) return false
  console.log(value)

  if(typeof value === 'string') {
    console.log(value.trim())
    return value.trim() !== ''
  }

  return true
}

export const requiredValidator = helpers.withMessage('Este campo é obrigatório', isRequired)