

<template>
<header class="header_desktop">
  <ul class="d-flex  links">
    <li @click="goToInicio"       >Inicio</li>
    <li @click="goToProjetos"     >Projetos</li>
  </ul>
  <ul v-if="!logado" class="d-flex align-content-end   justify-content-end login">
    <li @click="goToSignUp">
      <span class="d-flex align-content-center">
        Sign up
      </span>
    </li>
    <li @click="goToLogin">Login</li>
  </ul>
  <ul v-if="logado" class="d-flex align-content-end justify-content-end login">
	<li @click="logout"       >Logout</li>
	<li @click="goToDashboard">DashBoard</li>
	<li @click="goToPerfil"   >Perfil</li>
  </ul>
</header>

  <div class="header_mobile">
	<v-app-bar
	  color="primary"
	  prominent
	>
	  <v-app-bar-nav-icon variant="text" @click="mobileDrawer = !mobileDrawer"></v-app-bar-nav-icon>

	  <v-toolbar-title>SideQuest</v-toolbar-title>

	  <v-spacer></v-spacer>

	  <v-btn v-if="!logado" text="true" @click="goToSignUp">Sign up</v-btn>
	  <v-btn v-if="!logado" text="true" @click="goToLogin">Login</v-btn>
	  <v-btn v-if="logado" text="true" @click="logout">Logout</v-btn>
	  <v-btn v-if="logado" text="true" @click="goToPerfil">Perfil</v-btn>
	</v-app-bar>

	<v-navigation-drawer
	  v-model="mobileDrawer"
	  :location="$vuetify.display.mobile ? 'left' : undefined"
	  temporary>
	  <v-list nav density="compact">
		<v-list-item @click-once="goToInicio"   prepend-icon="mdi-home" title="Inicio" value="myfiles"></v-list-item>
		<v-list-item @click-once="goToDashboard"   prepend-icon="mdi-view-dashboard" title="Meu DashBoard" value="starred"></v-list-item>
		<v-list-item @click-once="goToProjetos" prepend-icon="mdi-newspaper" title="Projetos" value="shared"></v-list-item>
	  </v-list>
	</v-navigation-drawer>
  </div>


</template>

<style lang="scss" scoped>
.header_desktop{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 50px;
  color: #00E1FF;
  padding-top: 10px;
  font-size: 16px;
  list-style: none;
  margin-top: 1em;
}


.links{
  width: 30%;
  padding-left: 4em;
}
li{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
  padding: 1.1em;
  width: fit-content;
  margin-right: 1.5em;
  cursor: pointer;
  border : 2px solid #e7e7e7;
  border-radius: 25px;
}
span{
  text-align: center;
}
.login{
  min-width: 20%;
  padding-right: 4em;
}
li{
  width: 10em;
  position: relative;
  height: 3.5em;
  border: 3px ridge #149CEA;
  outline: none;
  background-color: transparent;
  color: white;
  transition: 1s;
  border-radius: 0.3em;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
li::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 3%;
  width: 95%;
  height: 40%;
  background-color: #01051a;
  transition: 0.5s;
  transform-origin: center;
}
li::before {
  content: "";
  transform-origin: center;
  position: absolute;
  top: 80%;
  left: 3%;
  width: 95%;
  height: 40%;
  background-color: #01051a;
  transition: 0.5s;
}
li:hover::before, li:hover::after {
  transform: scale(0)
}
li:hover {
  box-shadow: inset 0 0 25px #1479EA;
}
ul{
  width: 50%;
}

.header_mobile{
  display: none;
}
@media screen and (max-width: 750px) {
  .header_desktop {
	display: none;
  }
  .header_mobile{
	display: flex;
	width: 100%;
  }
}
</style>



<script setup  lang="ts">

import { useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import useCookieService from '@/services/CookieService'



const router = useRouter()
let token = useCookieService().getToken();
let roleUser = useCookieService().getAuthCookie().role

const logado = computed(() => {
  return token != "" && token != null
})


const mobileDrawer = ref(false);

const goToInicio = () => {
  router.push('/')
}
const goToProjetos= () => {
  router.push('/projetos')
}
const goToSignUp= () => {
  router.push('/register')
}
const goToLogin= () => {
  router.push('/login')
}
const goToPerfil= () => {
  router.push('/perfil')
}
const goToDashboard= () => {
  router.push('/dashboard')
}

const logout = () => {
  useCookieService().logout();
  token = "";
  router.push('/')
}


</script>