<template>
  <MainHeader/>
  <v-container class="content">
	<v-dialog max-width="500" v-model="participarDemandaDialog">
	  <template v-slot:default="{ isActive }">
		<v-card title="Participar de demanda">
		  <v-card-text>
			Deseja realmente participar dessa demanda?
		  </v-card-text>
		  <v-card-actions>
			<v-btn
			  color="green"
			  text="Participar de demanda"
			  variant="flat"
			  @click="isActive.value = false"
			></v-btn>
			<v-btn
			  color="red"
			  text="cancelar"
			  variant="flat"
			  @click="isActive.value = false"
			></v-btn>
		  </v-card-actions>
		</v-card>
	  </template>
	</v-dialog>
	<v-dialog v-model="atualizarStatusDemandaDialog" max-width="500px">
	  <v-card>
		<v-card-title>Mudar Status da Demanda</v-card-title>
		<v-card-text>
		  <v-select
			v-model="selectedStatus"
			:items="statusDemanda"
			:item-props="itemPropsStatusDemanda"
			item-title="label"
			item-value="value"
			label="Novo Status"
			return-object
		  ></v-select>
		</v-card-text>
		<v-card-actions>
		  <v-spacer></v-spacer>
		  <v-btn color="blue-darken-1" variant="text" @click="atualizarStatusDemandaDialog = false">
			Cancelar
		  </v-btn>
		  <v-btn color="blue-darken-1" variant="text" @click="changeStatus">
			Salvar
		  </v-btn>
		</v-card-actions>
	  </v-card>
	</v-dialog>
	<v-dialog v-model="aprovarAlunoDialog" transition="dialog-bottom-transition">
	  <v-card>
		<v-toolbar dark color="primary">
		  <v-btn icon dark @click="aprovarAlunoDialog = false">
			<v-icon>mdi-close</v-icon>
		  </v-btn>
		  <v-toolbar-title>Aprovação de Inscrições</v-toolbar-title>
		  <v-spacer></v-spacer>
		  <v-toolbar-items>
			<v-btn dark text @click="aprovarAlunoDialog = false">Fechar</v-btn>
		  </v-toolbar-items>
		</v-toolbar>

		<v-card-text>
		  <v-data-table
			v-if="demanda.inscricoes.length > 0"
			:headers="headersAprovacao"
			:items="demanda.inscricoes"
			:items-per-page-options="[5,10,15,20]"
			:loading-text="'Carregando demandas'"
			:items-per-page-text="'Inscricões por pagina'"
			:no-data-text="'Não há inscricões vinculadas a esse projeto'"
			:search="searchAprovacao"
			:custom-filter="filtroPersonalizado"
			class="elevation-1"
		  >
			<template v-slot:top>
			  <v-text-field
				v-model="searchAprovacao"
				label="Buscar"
				class="mx-4"
			  ></v-text-field>
			</template>

			<template v-slot:item.aluno="{ item }">
			  {{ item.aluno.nome + " " + item.aluno.sobrenome }}
			</template>
			<template v-slot:item.statusIncricao="{ item }">
			  {{ item.statusIncricao.descricao }}
			</template>

			<template v-slot:item.dataInscricao="{ item }">
			  {{ moment(item.dataInscricao).format("DD/MM/YYYY") }}
			</template>

			<template v-slot:item.actions="{ item }">

			  <v-btn
				v-if="item.statusIncricao.codigo == 0"
				color="success"
				small
				class="mr-2"
				@click="aprovarInscricao(item)"
			  >
				Aprovar
			  </v-btn>
			  <v-btn
				v-if="item.statusIncricao.codigo == 0"
				color="error"
				small
				@click="rejeitarInscricao(item)"
			  >
				Rejeitar
			  </v-btn>
			</template>
		  </v-data-table>

		  <v-card-text v-else>
			Não há inscrições pendentes para aprovação.
		  </v-card-text>
		</v-card-text>
	  </v-card>
	</v-dialog>
	<v-row>
	  <v-col cols="12">
		<v-img :src="'https://images3.alphacoders.com/632/thumb-1920-632075.jpg'"  height="200" cover class="border-radius" >
		</v-img>
	  </v-col>
	</v-row>

	<v-row>
	  <v-col cols="12" md="8">
		<h1 class="text-h3 mb-4 titulo">{{ demanda.titulo }}</h1>
		<v-chip class="mr-2 mb-4" :color="demanda?.statusDemanda?.cor">
		  {{ demanda?.statusDemanda?.descricao }}
		</v-chip>
		<v-chip class="mb-4" :color=" demanda?.areaTematica?.cor ">
		  {{ demanda?.areaTematica?.descricao }}
		</v-chip>
	  </v-col>
	  <v-col cols="12" md="8">
		<v-btn v-if="ehAluno && estaAguardandoInscricoes && !jaEstaParticipando" color="green" class="ml-3 mb-6" @click="participarDemanda">
		  Participar da Demanda
		</v-btn>
		<v-btn v-if="ehAluno && jaEstaParticipando" disabled color="green" class="ml-3 mb-6">
		  Ja está participando
		</v-btn>
		<v-btn  v-if="ehCoordenador" color="orange" class="ml-3 mb-6" @click="atualizarDemanda">
		  Atualizar status
		</v-btn>
		<v-btn  v-if="ehCoordenador || donoDaDemanda" color="cyan" class="ml-3 mb-6" @click="editarDemanda">
		  Editar Demanda
		</v-btn>
		<v-btn  v-if="ehCoordenador" color="purple" class="ml-3 mb-6" @click="aprovarAlunoDialog = true">
		  Inscricões dos alunos
		</v-btn>

	  </v-col>


	  <v-col cols="12" md="8">
		<v-expansion-panels>
		  <v-expansion-panel v-if="demanda.descricao">
			<v-expansion-panel-title>Descrição</v-expansion-panel-title>
			<v-expansion-panel-text>
			  <EditorMarkdown v-model="demanda.descricao" readonly />
			</v-expansion-panel-text>
		  </v-expansion-panel>

		  <v-expansion-panel v-if="demanda.objetivoGeral">
			<v-expansion-panel-title>Objetivo Geral</v-expansion-panel-title>
			<v-expansion-panel-text>
			  <EditorMarkdown v-model="demanda.objetivoGeral" readonly />
			</v-expansion-panel-text>
		  </v-expansion-panel>

		  <v-expansion-panel v-if="demanda.fundamentoTeorico">
			<v-expansion-panel-title>Fundamento Teórico</v-expansion-panel-title>
			<v-expansion-panel-text>
			  <EditorMarkdown v-model="demanda.fundamentoTeorico" readonly />
			</v-expansion-panel-text>
		  </v-expansion-panel>

		  <v-expansion-panel v-if="demanda.metodologia">
			<v-expansion-panel-title>Metodologia</v-expansion-panel-title>
			<v-expansion-panel-text>
			  <EditorMarkdown v-model="demanda.metodologia" readonly />
			</v-expansion-panel-text>
		  </v-expansion-panel>

		  <v-expansion-panel v-if="demanda.avaliacao">
			<v-expansion-panel-title>Avaliação</v-expansion-panel-title>
			<v-expansion-panel-text>
			  <EditorMarkdown v-model="demanda.avaliacao" readonly />
			</v-expansion-panel-text>
		  </v-expansion-panel>

		  <v-expansion-panel v-if="demanda.resultadoEsperado">
			<v-expansion-panel-title>Resultado Esperado</v-expansion-panel-title>
			<v-expansion-panel-text>
			  <EditorMarkdown v-model="demanda.resultadoEsperado" readonly />
			</v-expansion-panel-text>
		  </v-expansion-panel>

		  <v-expansion-panel v-if="demanda.elaboracao">
			<v-expansion-panel-title>Elaboração</v-expansion-panel-title>
			<v-expansion-panel-text>
			  <EditorMarkdown v-model="demanda.elaboracao" readonly />
			</v-expansion-panel-text>
		  </v-expansion-panel>

		  <v-expansion-panel v-if="demanda.equipamentoCampus">
			<v-expansion-panel-title>Equipamento do Campus</v-expansion-panel-title>
			<v-expansion-panel-text>
			  <EditorMarkdown v-model="demanda.equipamentoCampus" readonly />
			</v-expansion-panel-text>
		  </v-expansion-panel>

		  <v-expansion-panel v-if="demanda.espacoFisicoCampus">
			<v-expansion-panel-title>Espaço Físico do Campus</v-expansion-panel-title>
			<v-expansion-panel-text>
			  <EditorMarkdown v-model="demanda.espacoFisicoCampus" readonly />
			</v-expansion-panel-text>
		  </v-expansion-panel>

		  <v-expansion-panel v-if="demanda.recursosFinanceiroCampus">
			<v-expansion-panel-title>Recursos Financeiros do Campus</v-expansion-panel-title>
			<v-expansion-panel-text>
			  <EditorMarkdown v-model="demanda.recursosFinanceiroCampus" readonly />
			</v-expansion-panel-text>
		  </v-expansion-panel>
		</v-expansion-panels>
	  </v-col>

	  <v-col cols="12" md="4">
		<v-card>
		  <v-card-title>Informações do Projeto</v-card-title>
		  <v-card-text>
			<p @click="goToPerfil(demanda.coordenador.id)" class="clickable">
			  <strong>Coordenador:</strong>
			  {{ demanda.coordenador.nome+" "+demanda.coordenador.sobrenome }}
			</p>
			<p @click="goToPerfil(demanda.demandante.id)" class="clickable">
			  <strong>Demandante:</strong>
			  {{ demanda.demandante.nome+" "+demanda.demandante.sobrenome }}
			</p>
			<p><strong>Data de Criação:</strong> {{ moment(demanda.dataCriacao).format('DD/MM/YYYY') }}</p>
			<p><strong>Inscrições:</strong> {{ demanda.inscricoes.length }}</p>
		  </v-card-text>
		</v-card>

		<v-card class="mt-4">
		  <v-card-title>Ações de Extensão</v-card-title>
		  <v-card-text>
			<v-expansion-panels>
			  <v-expansion-panel v-for="acao in demanda.acoes" :key="acao.idAcaoDeExtensao">
				<v-expansion-panel-title>
				  <v-row no-gutters>
					<v-col cols="6">Ação</v-col>
					<v-col cols="6" class="text-right">
					  <v-chip size="small" :color="acao.tipo.cor">{{ acao.tipo.descricao }}</v-chip>
					</v-col>
				  </v-row>
				</v-expansion-panel-title>
				<v-expansion-panel-text>
				  <EditorMarkdown v-model="acao.descricao" readonly />
				  <v-divider class="my-2"></v-divider>
				  <div v-if="acao.objetivosDeDesenvolvimento.length > 0">
					<strong>Objetivos de Desenvolvimento:</strong>
					<v-chip-group>
					  <v-chip v-for="objetivo in acao.objetivosDeDesenvolvimento" :key="objetivo.id" :color="objetivo.cor" small>
						{{ objetivo.descricao }}
					  </v-chip>
					</v-chip-group>
				  </div>
				</v-expansion-panel-text>
			  </v-expansion-panel>
			</v-expansion-panels>
		  </v-card-text>
		</v-card>
	  </v-col>
	</v-row>
  </v-container>
</template>

<script setup lang="ts">
import { computed, defineProps, onMounted, ref } from 'vue'
import EditorMarkdown from '@/components/EditorMarkdown.vue'
import MainHeader from '@/components/MainHeader.vue'
import useDemandaService from '@/services/DemandaService.js'
import { useRouter } from 'vue-router'
import { transformarObjetoEmArray } from '@/utils/MapUtil'
import { AcaoTematica } from '@/enum/areaTematicaEnum'
import { AcaoTipo } from '@/enum/acaoTipoEnum'
import { ObjetivoDesenvolvimento } from '@/enum/objetivosDeDesenvolvimentoEnum'
import { StatusDemanda } from '@/enum/StatusDemandaEnum'
import { StatusAprovacao} from '@/enum/StatusAprovacao'
import moment from 'moment/moment'
import useCookieService from '@/services/CookieService'
import useParticipacaoService from '@/services/ParticipacaoService'

//constantes
const router = useRouter()
const tokenDTO = useCookieService().getAuthCookie();
const props = defineProps({
  id: {
	type: Number,
	required: true
  }})


//variaveis
const demanda = ref({
  // Populate this with your actual data
  id: 1,
  titulo: 'Título do Projeto',
  descricao: 'Descrição do projeto...',
  coordenador: { nome: 'Nome do Coordenador' },
  demandante: { nome: 'Nome do Demandante' },
  imagemHeader: 'https://images3.alphacoders.com/632/thumb-1920-632075.jpg',
  dataCriacao: '',
  inscricoes: [],
  areaTematica: {},
  statusDemanda: {},
  acoes: [
	{ idAcaoDeExtensao: 1, tipo: 'Tipo de Ação', descricao: 'Descrição da ação', publicoDeAcao: 'Público Alvo' }
  ],
  objetivoGeral: 'Objetivo geral do projeto...',
  fundamentoTeorico: 'Fundamento teórico do projeto...',
  metodologia: 'Metodologia do projeto...',
  avaliacao: 'Avaliação do projeto...',
  resultadoEsperado: 'Resultado esperado do projeto...',
  elaboracao: 'Elaboração do projeto...',
  equipamentoCampus: 'Equipamentos necessários...',
  espacoFisicoCampus: 'Espaço físico necessário...',
  recursosFinanceiroCampus: 'Recursos financeiros necessários...'
})
const participarDemandaDialog = ref(false)
const atualizarStatusDemandaDialog = ref(false)
const aprovarAlunoDialog = ref(false)
const selectedStatus = ref()
const statusDemanda = ref(transformarObjetoEmArray(StatusDemanda))
const headersAprovacao = [{ title: 'Aluno', key: 'aluno' },
  { title: 'Status', key: 'statusIncricao' },
  { title: 'Inscreveu-se em', key: 'dataInscricao' },
  { title: 'Ações', key: 'actions' }]
const searchAprovacao = ref("")


//computed
const ehAluno = computed( ()=> {
	return tokenDTO.role == "ALUNO"
})
const ehCoordenador = computed ( () => {
  return tokenDTO.role == "COORDENADOR"
})
const donoDaDemanda = computed( () =>{
  return tokenDTO.id == demanda.value.demandante.id
})
const estaAguardandoInscricoes = computed ( () => {
  return demanda.value.statusDemanda.codigo == StatusDemanda.AGUARDANDO_INSCRICOES.codigo
})
const jaEstaParticipando = computed ( () => {
  return demanda.value.inscricoes.filter( (insc) => {
	return insc.aluno.id == tokenDTO.id
  } )
})

const itemPropsStatusDemanda = (status: any) => {
  return {
	title: status.descricao,
	value: status.codigo
  }
}


//funcoes
const goToPerfil = (id:any) =>{
  router.push(`/perfil/${id}`, )
}
const changeStatus = () => {
  useDemandaService().atualizarStatus(demanda.value.id,selectedStatus.value.codigo).then( () => {
	demanda.value.statusDemanda = Object.values(StatusDemanda).find((status) => status.codigo === selectedStatus.value.codigo);
	atualizarStatusDemandaDialog.value = false
  })
}
onMounted( () => {
  const id = props.id;
  fetchDemanda(id)

})
const fetchDemanda = (id:any) => {
  useDemandaService().getDemanda(id).then( (res) => {
	console.log(res)
	demanda.value = res.data
	demanda.value.areaTematica = Object.values(AcaoTematica).find((acao) => acao.codigo === demanda.value.areaTematica);
	demanda.value.statusDemanda = Object.values(StatusDemanda).find((status) => status.codigo === demanda.value.statusDemanda);
	demanda.value.acoes = res.data.acoes.map( (acao) => {
	  acao.tipo = Object.values(AcaoTipo).find((tipo) => {
		return tipo.codigo === acao.tipo
	  });
	  acao.objetivosDeDesenvolvimento = acao.objetivosDeDesenvolvimento.map( (objetivo) => {
		return Object.values(ObjetivoDesenvolvimento).find((enumeracao) => enumeracao.codigo == objetivo);
	  })
	  return acao;
	})
	demanda.value.inscricoes = res.data.inscricoes.map ( (insc)  => {
	  insc.statusIncricao = Object.values(StatusAprovacao).find((statusInsc) => {
		console.log(statusInsc)
		return statusInsc.descricao.toUpperCase() === insc.statusIncricao
	  });
	  return insc
	});
  })
}
const participarDemanda = () => {
  participarDemandaDialog.value = true;
}
const atualizarDemanda = () =>{
  atualizarStatusDemandaDialog.value = true;
}
const editarDemanda = () =>{
  router.push(`/editarProjeto/${demanda.value.id}`)
}
const aprovarInscricao = (item :any) => {
	useParticipacaoService().aprovarParticipante(item.id).then( (data) =>{
	  demanda.value.inscricoes.find( insc => insc.id == item.id).statusIncricao = StatusAprovacao.APROVADO
	})
}
const rejeitarInscricao = (item :any) => {
  useParticipacaoService().reprovarParticipante(item.id).then( (data) =>{
	demanda.value.inscricoes.find( insc => insc.id == item.id).statusIncricao = StatusAprovacao.REPROVADO
  })
}
const filtroPersonalizado = (item, search) =>{
  console.log(item)
  let nomeDoAluno
  let statusAlvo
  if(item?.nome){
	nomeDoAluno  = item?.nome?.toLowerCase()+" "+item?.sobrenome?.toLowerCase()
	const boolean = nomeDoAluno.includes(search.toLowerCase())
	return boolean
  }
  if(item?.descricao){
	statusAlvo = item?.descricao?.toLowerCase()
	const boolean = statusAlvo.includes(search.toLowerCase());
	return boolean
  }
	return false;
}


</script>
<style lang="scss">
.content{
  margin-top: 5em;

}
.border-radius{
  border-radius: 5px;
}
.titulo{
  color:#ffffff;
}

.clickable{
  cursor: pointer;

  &:hover{
	text-decoration: underline;
	color :#2d79f3;
  }
}
</style>