import * as APIUtils from '@/utils/APIUtils'
import type AuthenticationRequestDTO from '@/model/RegisterDTO'
import api from '@/apiService'


export function useAuthService(){
  const api_url = APIUtils.getAPIUrl()
  const endpoint = 'auth';

  const login = (request: AuthenticationRequestDTO) => {
    return api.post(`${api_url}/${endpoint}/login`, request)
  }

  const register = (request: AuthenticationRequestDTO) => {
    return api.post(`${api_url}/${endpoint}/register`, request)
  }

  return {
    api_url,
    endpoint,
    login,
    register
  }
}

export default useAuthService;
