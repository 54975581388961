<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import MainHeader from '@/components/MainHeader.vue'
import useDemandaService from '@/services/DemandaService'
import type DemandaDTO from '@/model/DemandaDTO'
import type { PageStatus } from '@/model/PageStatus'
import { StatusDemanda } from '@/enum/StatusDemandaEnum'
import moment from 'moment'

const demandaService = useDemandaService()
const router = useRouter()

const imagem = ref('https://images3.alphacoders.com/632/thumb-1920-632075.jpg')
const demandas = ref<DemandaDTO[]>([])
const pagestatus = ref<PageStatus>({})

const filtro = ref({
  tituloLike: '',
  coordenadorLike: '',
  demandanteLike: '',
  statusDemanda: [] as number[],
  page: 1,
  size: 5,
  totalPages: 0
})

const statusOptions = [
  { value: StatusDemanda.CONCLUIDA.codigo, label: StatusDemanda.CONCLUIDA.descricao },
  { value: StatusDemanda.EM_ANDAMENTO.codigo, label: StatusDemanda.EM_ANDAMENTO.descricao },
  { value: StatusDemanda.AGUARDANDO_APROVACAO.codigo, label: StatusDemanda.AGUARDANDO_APROVACAO.descricao },
  { value: StatusDemanda.AGUARDANDO_INSCRICOES.codigo , label: StatusDemanda.AGUARDANDO_INSCRICOES.descricao },
  { value: StatusDemanda.CANCELADA.codigo, label: StatusDemanda.CANCELADA.descricao }
]

onMounted(async () => {
  await buscaDemandasInicias()
})

const buscaDemandasInicias = async () => {
  try {
    const tempFiltro = {...filtro.value, page: filtro.value.page -1}
    const response = await demandaService.getDemandasFiltradas(tempFiltro)
    demandas.value = response.data.content
    updatePageStatus(response.data)
  } catch (error) {
    console.error('Error fetching initial demands:', error)
  }
}

const aplicarFiltro = async () => {
  filtro.value.page = 1
  await buscarDemandas()
}

const buscarDemandas = async () => {
  try {
    const tempFiltro = {...filtro.value, page: filtro.value.page -1}
    const response = await demandaService.getDemandasFiltradas(tempFiltro)
    demandas.value = response.data.content
    updatePageStatus(response.data)
  } catch (error) {
    console.error('Error fetching demands:', error)
  }
}

const updatePageStatus = (data: any) => {
  pagestatus.value = {
    pageable: data?.pageable,
    totalPages: data?.totalPages,
    totalElements: data?.totalElements,
    last: data?.last,
    first: data?.first,
    size: data?.size,
    number: data?.number,
    sort: data?.sort,
    numberOfElements: data?.numberOfElements,
    empty: data?.empty
  }
  filtro.value.totalPages = data?.totalPages || 0
}

const irParaPaginaDemanda = (id?: number) => {
  router.push(`/projeto/${id}`)
}

const statusDemandaToEnumClass = (status: string) => {
  return  Object.values(StatusDemanda).find( (statusEnum ) => statusEnum.codigo === status).cor
}

const statusDemandaToEnum = (status: string) => {
    return Object.values(StatusDemanda).find ( (statusEnum) => statusEnum.codigo === status).descricao
}
</script>

<template>
    <MainHeader />
    <v-main class="main">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="3">
            <v-card class="pa-4">
              <v-card-title>Filtros</v-card-title>
              <v-text-field v-model="filtro.tituloLike" label="Titulo" placeholder="Ex: manutenção" />
              <v-text-field v-model="filtro.coordenadorLike" label="Coordenador" placeholder="Ex: Robson" />
              <v-text-field v-model="filtro.demandanteLike" label="Demandante" placeholder="Ex: Robson" />
              <v-divider class="my-4" />
              <v-card-subtitle>Status</v-card-subtitle>
              <v-select
                v-model="filtro.statusDemanda"
                :items="statusOptions"
                item-title="label"
                item-value="value"
                multiple
                chips
                label="Selecione os status"
              />
              <v-btn color="primary" @click="aplicarFiltro" class="mt-4">Aplicar Filtros</v-btn>
            </v-card>
          </v-col>
          <v-col cols="12" md="9">
            <v-card v-if="demandas.length === 0" class="text-center pa-4">
              Não há demandas com esse filtro
            </v-card>
            <v-card v-for="demanda in demandas" :key="demanda?.id" @click="irParaPaginaDemanda(demanda.id)" class="mb-4">
              <v-row no-gutters>
                <v-col cols="3">
                  <v-img :src="imagem" height="100%" cover />
                </v-col>
                <v-col cols="9">
                  <v-card-text>
                    <v-row>
                      <v-col cols="4">
                        <div class="text-subtitle-2">Título</div>
                        <div>{{ demanda?.titulo || 'Sem título' }}</div>
                      </v-col>
                      <v-col cols="4">
                        <div class="text-subtitle-2">Demandante</div>
                        <div>{{ demanda.demandante?.nome+" "+demanda.demandante?.sobrenome || "Sem demandante" }}</div>
                      </v-col>
                      <v-col cols="4">
                        <div class="text-subtitle-2">Coordenador</div>
                        <div>{{ demanda.coordenador?.nome+" "+demanda.coordenador.sobrenome || "Sem coordenador" }}</div>
                      </v-col>
                      <v-col cols="4">
                        <div class="text-subtitle-2">Status</div>
                        <v-chip :color="statusDemandaToEnumClass(demanda.statusDemanda)">
                          {{ statusDemandaToEnum(demanda.statusDemanda) || "Sem status" }}
                        </v-chip>
                      </v-col>
                      <v-col cols="4">
                        <div class="text-subtitle-2">Data de criação</div>
                        <div>{{ moment(demanda?.dateCriacao).format("DD/MM/YYYY") || "Sem data de criação" }}</div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
            <v-pagination
              v-model="filtro.page"
              :length="filtro.totalPages"
              :total-visible="7"
              @update:model-value="buscarDemandas"
              :color="'white'"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
</template>

<style lang="scss">
.main{
  background: radial-gradient(circle at 78% 55%, #0083AC 0%, #00122E 10%, #01051A 19%);
}
</style>