<template>
  <MainHeader/>
  <v-container fluid class="fill-height background mt-15">
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Registro de Usuário</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="v$.email.$model"
                    label="E-mail"
  					:error-messages="v$.email.$errors.map( e => e.$message)"
                    prepend-icon="mdi-email"
					@input="checkEmailDomain"
                  />
                    <v-alert
                      v-if="userType"
                      :type="userType === 'coordenador' ? 'warning' : 'info'"
                      dense
                      text
                    >
                      Você será cadastrado como {{ userType }}.
                    </v-alert>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="10">
                  <v-text-field
                  v-model="v$.password.$model"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
				  :error-messages="v$.password.$errors.map( e => e.$message)"
                  label="Senha"
                  prepend-icon="mdi-lock"
                  @click:append="showPassword = !showPassword"
                  />
                </v-col>
                <v-col cols="2">
                  <v-tooltip text="Deve conter pelo menos uma letra Maiúsculas , Minúsculas, um caracter especial e um numero">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" icon="mdi-information "></v-icon>
                    </template>
                  </v-tooltip>
                </v-col>
              </v-row>


            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="v$.passwordConfirmation.$model"
                  :type="showPassword ? 'text' : 'password'"
				  :error-messages="v$.passwordConfirmation.$errors.map( e => e.$message)"
                  label="Confirme a senha"
                  prepend-icon="mdi-lock-check"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="v$.celular.$model"
				  :error-messages="v$.celular.$errors.map( e => e.$message)"
                  label="Celular"
				  v-mask="[ '(##) #####-####', '(##) #####-####', '###########']"
                  prepend-icon="mdi-cellphone"
                ></v-text-field>
              </v-col>
            </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="v$.nome.$model"
                    label="Nome"
					:error-messages="v$.nome.$errors.map( e => e.$message)"
                    prepend-icon="mdi-account"
                  ></v-text-field>
                </v-col>
              </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="v$.sobrenome.$model"
				  :error-messages="v$.sobrenome.$errors.map( e => e.$message)"
                  label="Sobrenome"
                  prepend-icon="mdi-account"
                ></v-text-field>
              </v-col>
            </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="v$.cpfCnpj.$model"
					:error-messages="v$.cpfCnpj.$errors.map( e => e.$message)"
					v-mask="['###.###.###-##', '##.###.###/####-##']"
                    label="CPF/CNPJ"
                    prepend-icon="mdi-card-account-details"
                  ></v-text-field>
                </v-col>
              </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="submitForm">
              Cadastrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, sameAs, helpers, minLength, maxLength } from '@vuelidate/validators'
import { isPasswordValid, passwordValidator } from '@/validador/password.ts'
import { isPasswordConfirmationValid } from '@/validador/passwordConfirmation.ts'
import { cpfCnpjValidator, isCpfCnpjValid } from '@/validador/cpf/cnpj.ts'
import { emailValidator } from '@/validador/email.ts'
import MainHeader from '@/components/MainHeader.vue'
import useAuthService from '@/services/AutenticacaoService.ts'
import { ToastService } from '@/services/ToastService.ts'
import {
  ErroAoCriarUsuario, JaExisteUsuario,
  PreenchaOsCamposCorretamente,
  UsuarioCriadoComSucesso
} from '@/components/Toast/ToastConst.ts'
import { useRoute, useRouter } from 'vue-router'
const rules = {
  email: {
	required: helpers.withMessage("O campo email é obrigatório", required),
	minLength: helpers.withMessage("Email muito curto (min 3)", minLength(3)),
	maxLength: helpers.withMessage("Email muito longo (max 25)", maxLength(50)) },
  password: {
	requirido : helpers.withMessage('Senha é obrigatoria', required),
	senha : helpers.withMessage("Senha não é valida", isPasswordValid)
  },
  passwordConfirmation: {
	requirido: helpers.withMessage("A confirmação é obrigatoria", required),
	sameAsPassword: helpers.withMessage("As senhas não são iguais", isPasswordConfirmationValid)
  },
  celular: {
	requiredo: helpers.withMessage('Celular obrigatorio', required),
	maxLength: helpers.withMessage("Telefone muito longo (max 30)", maxLength(30))
  },
  nome: {
	requiredo : helpers.withMessage("Nome é obrigatoria", required),
	minLength: helpers.withMessage("Nome muito curto (min 3)", minLength(3)),
	maxLength: helpers.withMessage("Nome muito longo (max 15)", maxLength(15))
  },
  sobrenome: {
	requiredo : helpers.withMessage("Sobrenome é obrigatorio",required),
	minLength: helpers.withMessage("Sobrenome muito curto (min 3)", minLength(3)),
	maxLength: helpers.withMessage("Sobrenome muito longo (max 50)", maxLength(50))
  },
  cpfCnpj: {
	requiredo : helpers.withMessage("CPF/CNPJ é obrigatorio", required),
	cpfCnpj:  helpers.withMessage("CPF/CNPJ não é valido", isCpfCnpjValid),
  }
}

const form = ref({
  email: '',
  password: '',
  passwordConfirmation: '',
  celular: '',
  nome: '',
  sobrenome: '',
  cpfCnpj: ''
})

const v$ = useVuelidate(rules, form)

const showPassword = ref(false)
const userType = ref(null)




const router = useRouter();

const checkEmailDomain = () => {
  if (form.value.email.endsWith('@ifsp.edu.br')) {
    userType.value = 'coordenador'
  } else if (form.value.email.endsWith('@aluno.ifsp.edu.br')) {
    userType.value = 'aluno'
  } else {
    userType.value = null
  }
}

const submitForm = async () => {
  const isValid = await v$.value.$validate()
  if (isValid) {
	useAuthService().register(form.value).then(() => {
	  ToastService.getInstance().toast(UsuarioCriadoComSucesso)
	  router.push("/")
	}).catch((error) => {

	  if(error.response.data.code){
		ToastService.getInstance().toast(JaExisteUsuario)
		return
	  }
	  ToastService.getInstance().toast(ErroAoCriarUsuario)
	});
  }
  else{
	ToastService.getInstance().toast(PreenchaOsCamposCorretamente)
  }
}

</script>

<style scoped>
.background {
  background: radial-gradient(circle at 78% 55%, #0083AC 0%, #00122E 10%, #01051A 19%);
}
</style>