import api from '@/apiService';
import * as APIUtils from '@/utils/APIUtils';
import apiFile from '@/apiFileService'

export function useProfileService() {
  const api_url = APIUtils.getAPIUrl();
  const endpoint = 'profile';

  const getProfile = (idUsuario : number | null) => {
    const url = idUsuario ? `${api_url}/${endpoint}/${idUsuario}` : `${api_url}/${endpoint}`
    return api.get(url);
  };

  const updateProfile = (data: any) => {
    return api.patch(`${api_url}/${endpoint}`, data);
  };

  const updateAvatar = (file: File) => {
    const formData = new FormData();
    formData.append('avatar', file);
    return api.patch(`${api_url}/${endpoint}/avatar`, formData );
  };

  const updateHeader = (file: any) => {
    const formData = new FormData();
    console.log(file)
    formData.append('header', file);
    return apiFile.patch(`${api_url}/${endpoint}/header`, formData);
  };

  return {
    api_url,
    endpoint,
    getProfile,
    updateProfile,
    updateAvatar,
    updateHeader,
  };
}
