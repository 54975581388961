import axios from 'axios';
import { getAPIUrl } from '@/utils/APIUtils'
import { useCookies } from 'vue3-cookies'

const api = axios.create({
  baseURL: getAPIUrl(),
});



api.interceptors.request.use((config) => {
  const { cookies } = useCookies();
  const token = cookies.get('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept'] = 'application/json';
    config.headers['Access-Control-Allow-Origin'] = '*';
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;