<template>
  <main class="background  d-flex  flex-column align-content-center">
    <MainHeader/>
    <div class="content d-flex  justify-content-center align-content-center">
      <form class="form" @submit.prevent>

        <div class="inputForm">
		  <v-text-field
			v-model.lazy="v$.email.$model"
			:rules="[v$.email.requiredValidator, v$.email.emailValidator]"
			label="Email"
			:error-messages="v$.email.$errors.map(e => e.$message)"
			placeholder="Ex: Robson@ifsp.edu.br"
			class="input"
			></v-text-field>
        </div>

        <div class="flex-column">
		  <v-text-field
			class="password_input"
			v-model.lazy="v$.password.$model"
			:append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
			:error-messages="v$.password.$errors.map(e => e.$message)"
			:rules="[v$.password.requiredValidator]"
			:type="passwordVisible ? 'text' : 'password'"
			label="Senha"

			counter
			@click:append="passwordVisible = !passwordVisible"
		  ></v-text-field>

		</div>

        <div class="flex-row">
          <div @click="featureNaoImplementada()">
            <input type="checkbox">
            <label class="ml-2">Lembre-se de mim</label>
          </div>
          <span class="span" @click="featureNaoImplementada()">Esqueceu da senha?</span>
        </div>
        <button class="button-submit" @click="submit">Entrar</button>
        <p class="p">
		  Não tem uma conta ainda?
		  <span class="span" @click="goToSignUp()" >Cadastre-se</span>
        </p>
		<p class="p line">Ou entre com</p>

        <div class="flex-row">
          <button class="btn google" @click="featureNaoImplementada()">
            <img src="@/assets/svg/google.svg" alt="My image" />
            Google
          </button>
        </div>
      </form>

    </div>
  </main>
</template>

<style scoped>
.content{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  padding: 30px;
  width: 450px;
  border-radius: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.form button {
  align-self: flex-end;
}
.flex-column > label {
  color: #151717;
  font-weight: 600;
}
.inputForm {

  height: 50px;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out;
}
.password_input{
  margin-top: 3em;

}
.input {
  width: 85%;
  height: 100%;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.flex-row > div > label {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.span {
  font-size: 14px;
  margin-left: 5px;
  color: #2d79f3;
  font-weight: 500;
  cursor: pointer;
}
.button-submit {
  margin: 20px 0 10px 0;
  background-color: #151717;
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  cursor: pointer;
}
.button-submit:hover {
  background-color: #252727;
}
.p {
  text-align: center;
  color: black;
  font-size: 14px;
  margin: 5px 0;
}
.btn {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  border: 1px solid #ededef;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.btn:hover {
  border: 1px solid #2d79f3;
;
}

@media screen and (max-width: 549px) {
  .form{
    margin-top: 30px;
  }

}

</style>

<script setup lang="ts">
import MainHeader from '@/components/MainHeader.vue'
import { computed, ref } from 'vue'
import { requiredValidator } from '@/validador/required'
import { emailValidator } from '@/validador/email'
import { useVuelidate } from '@vuelidate/core'
import { useRouter } from 'vue-router'
import { ToastService } from '@/services/ToastService'
import {
  camposIncompleto,
  ErroInterno,
  FeatureNaoImplementada,
  LoginInvalido,
  LoginSucesso, PreenchaOsCamposCorretamente
} from '@/components/Toast/ToastConst'
import  { useAuthService } from '@/services/AutenticacaoService'
import type { AxiosError, AxiosResponse } from 'axios'
import type ErrorDTO from '@/model/ErrorDTO'
import type TokenDTO from '@/model/TokenDTO'
import useCookieService from '@/services/CookieService'

const rules = {
  email: {requiredValidator, emailValidator},
  password: {requiredValidator}
}
const form = ref({
  email: '',
  password: ''
})
const v$ = useVuelidate(rules, form)
const router = useRouter()
const featureNaoImplementada = () => {
  ToastService.getInstance().toast(FeatureNaoImplementada)
}
const goToSignUp = () => {
  router.push('/register')
}
const passwordVisible = ref(false)

const submit = () => {
  v$.value.$validate()
  if (v$.value.$error) {
	ToastService.getInstance().toast(PreenchaOsCamposCorretamente)
	return
  }
  useAuthService().login(form.value).then((response : AxiosResponse<TokenDTO>) => {

	useCookieService().registerCookie(response.data)
	ToastService.getInstance().toast(LoginSucesso)
	router.push('/')
  }).catch((error: AxiosError<ErrorDTO>) => {

	if(error.response?.data?.code === "BAD_CREDENTIALS"){
	  ToastService.getInstance().toast(LoginInvalido)
	  return
	}
	ToastService.getInstance().toast(ErroInterno)
  })}



</script>
