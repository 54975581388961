import { useCookies } from "vue3-cookies";
import type AuthDTO from '@/model/AuthDTO'
import type TokenDTO from '@/model/TokenDTO'

const useCookieService = () => {

  const time = 60 * 60 * 3;
  const getCookie = (name: string) => {
    const cookies =  useCookies().cookies;
    return cookies.get(name);
  }
  const setCookie = (name: string, value: string, time: number) => {
    const cookies =  useCookies().cookies;
    cookies.set(name, value, time);
  }
  const removeCookie = (name: string) => {
    const cookies =  useCookies().cookies;
    cookies.remove(name);
  }
  const registerCookieLogin = (token: string, role: string, id: string, email: string) => {
    const cookies =  useCookies().cookies;
    cookies.set('token', token, 60 * 60 * 3);
    cookies.set('role', role, 60 * 60 * 3);
    cookies.set('id', id, 60 * 60 * 3);
    cookies.set('email', email, 60 * 60 * 3);
  }
  const registerCookie = (tokenDTO : TokenDTO) => {
    const cookies =  useCookies().cookies;
    cookies.set('token', tokenDTO.token, time);
    cookies.set('role', tokenDTO.role, time);
    cookies.set('id', tokenDTO.id.toString(), time);
    cookies.set('email', tokenDTO.email, time);
    cookies.set('expiration', tokenDTO.expiration, time);
    cookies.set('created', tokenDTO.creation, time);
  }
  const logout = () => {
    const cookies =  useCookies().cookies;
    cookies.remove('token');
    cookies.remove('role');
    cookies.remove('id');
    cookies.remove('email');
    cookies.remove('expiration');
    cookies.remove('created');
  }
  const getAuthCookie = ():AuthDTO => {
    const cookies =  useCookies().cookies;
    return {
      token: cookies.get('token'),
      role: cookies.get('role'),
      id: cookies.get('id'),
      email: cookies.get('email'),
      expiration: cookies.get('expiration'),
      creation: cookies.get('created'),
    }
  }
  const getToken = () => {
    const cookies =  useCookies().cookies;
    return cookies.get('token');
  }
  const getRole = () => {
    const cookies =  useCookies().cookies;
    return cookies.get('role');
  }
  const getId = () => {
    const cookies =  useCookies().cookies;
    return cookies.get('id');
  }
  const getEmail = () => {
    const cookies =  useCookies().cookies;
    return cookies.get('email');
  }



  return {
    getCookie,
    setCookie,
    removeCookie,
    registerCookieLogin,
    logout,
    getToken,
    getRole,
    getId,
    getEmail,
    getAuthCookie,
    registerCookie
  }

}

export default useCookieService;