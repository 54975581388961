<script setup lang="ts">
import { marked } from 'marked'
import EditorMarkdown from '@/components/EditorMarkdown.vue'
import { computed, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
	type: String,
	required: false
  },
  editMode: {
	type: Boolean,
	default: false
  },
  label:{
	type: String,
	default: 'Bio'
  },
  error:{
	type: Boolean
  },
  limitChar:{
	type: Number
  },
  counter: {
	type:Boolean
  },
  errorMessages:{
	type: Array
  }
})

const emit = defineEmits(['update:modelValue'])

const markdownHtml = computed(() => {
  return marked(props.modelValue || '');
});

const valorInterno = computed({
  get() {
	return props.modelValue;
  },
  set(newValue) {
	emit('update:modelValue', newValue);
  }
});
</script>

<template>
  <div
	v-show="!editMode"
	v-html="markdownHtml">
  </div>
  <v-textarea
	:label="label"
	v-show="editMode"
	v-model="valorInterno"
	:error="error"
	:error-messages="errorMessages"
  />
</template>

<style scoped>

</style>
