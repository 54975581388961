import axios from 'axios';
import { getAPIUrl } from '@/utils/APIUtils'
import { useCookies } from 'vue3-cookies'

const apiFile = axios.create({
  baseURL: getAPIUrl(),
});


apiFile.interceptors.request.use((config) => {
  const { cookies } = useCookies();
  const token = cookies.get('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default apiFile;