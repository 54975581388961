import { ref } from 'vue'
import type { Ref } from 'vue'
import type ToastDTO from '@/model/ToastDTO'

export class ToastService {
  private static instance: ToastService;
  private static toastCounter = 0;
  public toasts: Ref<Array<ToastDTO>> = ref([])

  private constructor() {}

  public static getInstance(): ToastService {
    if (!ToastService.instance) {
      ToastService.instance = new ToastService();
    }

    return ToastService.instance;
  }

  private addToast( toast: ToastDTO ) {
    toast.id = Math.floor(Date.now().valueOf());
    this.toasts.value.push(toast);
  }

  removeToast(id: number) {
    this.toasts.value = this.toasts.value.filter(toast => toast.id !== id);
  }

  toast(toast : ToastDTO){
    this.addToast(toast)
    setTimeout(() => {
      this.removeToast(toast.id as number)
    }, toast.duration)
  }

}

export default ToastService.getInstance();