<script setup lang="ts">
import MainHeader from '@/components/MainHeader.vue'
import Logo from '@/components/Logo.vue'
import MainSubText from '@/components/MainSubText.vue'
import MainFooter from '@/components/MainFooter.vue'
import SearchInput from '@/components/SearchInput.vue'
import { ref } from 'vue'

const subtitulo = ref(import.meta.env.VITE_SUBTITLE)
const titulo = ref(import.meta.env.VITE_NAME)
</script>

<template>
  <MainHeader />
  <main class="home-view background">

	<div class="d-flex  justify-content-center align-content-center logo">
	  {{titulo ? titulo : "SideQuest"}}
	</div>
	<h2 class="d-flex justify-content-center align-content-center subtitulo" >
	  {{ subtitulo ? subtitulo : "Subtitulo"}}
	</h2>


	<SearchInput class="mt-15"/>

	<div class="text-box">
	  <div class="mt-15">
		<h5>Quem Somos?</h5>
		<p>
		  O SideQuest é uma plataforma de projetos de extensão que visa conectar demandantes da comunidade em forma de projetos com alunos e coordenadores do IFSP
		</p>
	  </div>

	  <img src="@assets/computer.png" alt="computer" class="mt-15" />
	</div>


	<div class="threeColumns">
	  <div class="column">
		<h3>Demandantes</h3>
		<p>Os demandantes podem buscar ajuda com seus negocios locais na comunidade, ou fazer um pedido que sera revisado por um coordenador para ser colocado em pratica </p>
	  </div>
	  <div class="column">
		<h3>Alunos</h3>
		<p>Os alunos podem procurar projetos para participar e montar portifolios com casos reais</p>
	  </div>
	  <div class="column">
		<h3>Coordenadores</h3>
		<p>Auxiliam os alunos e demandantes a trabalhar no projeto</p>
	  </div>
	</div>



  </main>
</template>

<style scoped lang="scss">
.home-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 2rem;
}
.subtitulo{
  color:#ffffff;
  font-size : 12px;
  text-align: center;
}
.logo{
  font-family: "VT323", monospace;
  font-weight: 400;
  color: #fff;
  text-shadow: 8px 8px 15px rgb(96 213 94);
  font-size: 5rem;
  border:0;
  margin-top: 0;
}

.text-box{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
	z-index: 2;
	width: 50%;
	color: white;
	font-size: 1.5rem;
	border : 1px solid white;
	padding: 1em;
	border-radius: 25px;
	background-color: rgba(3, 9, 35, 0.4);
	animation: fadeIn 2s;
  }
  & > img {
	right: -10%;
	width: 40em;
	position: absolute;
	animation-name:float ;
	animation-duration: 6s;
	animation-iteration-count: infinite;

  }
}

@keyframes float  {
  0%{top: 30%;}
  50%{top:35%;}
  100%{top:30%;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.threeColumns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2em;

  .column {
	width: 30%;
	background-color: rgba(32, 111, 133, 0.4);
	border-radius: 25px;
	padding: 1em;
	color: white;
  }
}

@media screen and (max-width: 750px) {
  .logo{
	margin-top: 2em;
	font-size: 5rem;
  }
  .text-box {
	& > div {
	  width: 100%;
	}
	& > img{
	  display: none;
	}
  }

  .threeColumns{
	flex-direction: column;
	.column {
	  width: 100%;
	  margin-top: 2em;
	}
  }
}

</style>