export const AcaoTematica = {
    CULTURA: { codigo: 1, descricao: "Cultura", cor: 'purple' },
    MEIO_AMBIENTE: { codigo: 2, descricao: "Meio Ambiente", cor: 'green' },
    DIREITOS_HUMANOS_E_JUSTICA: { codigo: 3, descricao: "Direitos Humanos e Justiça", cor: 'red' },
    TECNOLOGIA_PRODUCAO: { codigo: 4, descricao: "Tecnologia e Produção", cor: 'blue' },
    SAUDE: { codigo: 5, descricao: "Saúde", cor: 'cyan' },
    EDUCACAO: { codigo: 6, descricao: "Educação", cor: 'yellow' },
    TRABALHO: { codigo: 7, descricao: "Trabalho", cor: 'orange' },
    COMUNICACAO: { codigo: 8, descricao: "Comunicação", cor: 'lightblue' }
};
