export const ObjetivoDesenvolvimento = {
  ERRADICACAO_DA_PROBREZA: { codigo: 1, descricao: "Erradicação da Pobreza", cor: 'green' },
  FOME_ZERO_E_AGRICULTURA_SUSTENTAVEL: { codigo: 2, descricao: "Fome Zero e Agricultura Sustentável", cor: 'yellow' },
  SAUDE_BEM_ESTAR: { codigo: 3, descricao: "Saúde e Bem-Estar", cor: 'cyan' },
  EDUCACAO_QUALIDADE: { codigo: 4, descricao: "Educação de Qualidade", cor: 'blue' },
  IGUALDADE_GENERO: { codigo: 5, descricao: "Igualdade de Gênero", cor: 'purple' },
  AGUA_POTAVEL_SANEAMENTO: { codigo: 6, descricao: "Água Potável e Saneamento", cor: 'lightblue' },
  ENERGIA_LIMPA_ACESSIVEL: { codigo: 7, descricao: "Energia Limpa e Acessível", cor: 'orange' },
  TRABALHO_DECENTE_CRESCIMENTO_ECONOMICO: { codigo: 8, descricao: "Trabalho Decente e Crescimento Econômico", cor: 'brown' },
  INDUSTRIA_INOVACAO_INFRAESTRUTURA: { codigo: 9, descricao: "Indústria, Inovação e Infraestrutura", cor: 'grey' },
  REDUCAO_DESIGUALDADES: { codigo: 10, descricao: "Redução das Desigualdades", cor: 'red' },
  CIDADES_COMUNIDADES_SUSTENTAVEIS: { codigo: 11, descricao: "Cidades e Comunidades Sustentáveis", cor: 'green' },
  CONSUMO_PRODUCAO_RESPONSAVEL: { codigo: 12, descricao: "Consumo e Produção Responsáveis", cor: 'yellow' },
  ACAO_CONTRA_MUDANCA_GLOBAL_CLIMA: { codigo: 13, descricao: "Ação Contra a Mudança Global do Clima", cor: 'cyan' },
  VIDA_NA_AGUA: { codigo: 14, descricao: "Vida na Água", cor: 'blue' },
  VIDA_TERRESTRE: { codigo: 15, descricao: "Vida Terrestre", cor: 'green' },
  PAZ_JUSTICA_INSTITUICOES: { codigo: 16, descricao: "Paz, Justiça e Instituições Eficazes", cor: 'purple' },
  PARCERIAS_MEIOS_IMPLEMENTACAO: { codigo: 17, descricao: "Parcerias e Meios de Implementação", cor: 'grey' }
};
