import type ToastDTO from '@/model/ToastDTO'
import ErrorType from '@/enum/ErrorType'

export const camposIncompleto: ToastDTO = {
  message: 'Preencha todos os campos',
  type: ErrorType.Error,
  duration: 3000,
}

export const ErroInterno: ToastDTO = {
  message: 'Erro interno, tente novamente mais tarde',
  type: ErrorType.Error,
  duration: 3000,
}

export const LoginInvalido: ToastDTO = {
  message: 'As credenciais informadas estão incorretas.',
  type: ErrorType.Error,
  duration: 3000,
}
export const LoginSucesso: ToastDTO = {
  message: 'Login realizado com sucesso',
  type: ErrorType.Success,
  duration: 3000,
}
export const LogoutSucesso: ToastDTO = {
  message: 'Logout realizado com sucesso',
  type: ErrorType.Success,
  duration: 3000,
}
export const UsuarioNaoLogado: ToastDTO = {
  message: 'Realize o login para acessar a página',
  type: ErrorType.Error,
  duration: 3000,
}

export const UsuarioCriadoComSucesso : ToastDTO = {
  message: 'Usuário criado com sucesso',
  type: ErrorType.Success,
  duration: 3000,
}
export const ErroAoCriarUsuario: ToastDTO = {
  message: 'Erro ao criar usuário',
  type: ErrorType.Error,
  duration: 3000,
}

export const JaExisteUsuario: ToastDTO = {
  message: 'Já existe um usuário com esse email',
  type: ErrorType.Error,
  duration: 3000,
}



export const PreenchaOsCampos: ToastDTO = {
  message: 'Preencha todos os campos',
  type: ErrorType.Error,
  duration: 3000,
}

export const PreenchaOsCamposDeAcoes: ToastDTO = {
  message: 'Preencha todos os campos de ações',
  type: ErrorType.Error,
  duration: 3000,

}
export const CadastrarSucesso: ToastDTO = {
  message: 'Cadastro realizado com sucesso',
  type: ErrorType.Success,
  duration: 3000,
}

export const FeatureNaoImplementada: ToastDTO = {
  message: 'Feature não implementada, me pague que eu faço.',
  type: ErrorType.Error,
  duration: 3000,
}

export const ErroAoBuscarDemandas: ToastDTO = {
  message: 'Erro ao buscar demandas',
  type: ErrorType.Error,
  duration: 3000,
}

export const DemnandaCriadaComSucesso: ToastDTO = {
  message: 'Demanda criada com sucesso',
  type: ErrorType.Success,
  duration: 3000,
}

export const ErroAoBuscarDemanda : ToastDTO ={
  message: "Houve um erro ao buscar as demandas",
  type: ErrorType.Error,
  duration: 3000,
}


export const PreenchaOsCamposCorretamente: ToastDTO = {
  message: 'Preencha os campos corretamente',
  type: ErrorType.Error,
  duration: 3000,
}

export const MudancasSalvas : ToastDTO = {
  message: "Suas mudanças foram salvas com sucesso",
  type: ErrorType.Success,
  duration: 3000
}